import { createSlice } from '@reduxjs/toolkit';

// State that will be shared across the app
const initialState = {
    user: null,
    token: null,
    apiUrl: ""
};

// Slice for the global state
export const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {

        // Set the user id and token when the user logs in
        setLogin: (state, action) => {
            state.user = action.payload.user;
            state.token = action.payload.token;
        },

        // Clear the user id and token when the user logs out
        setLogout: (state) => {
            state.user = null;
            state.token = null;
        },

        // Set the API URL
        setApiUrl: (state, action) => {
            state.apiUrl = action.payload;
        }

    }
});

export const { setLogin, setLogout, setApiUrl } = globalSlice.actions;
export default globalSlice.reducer;
export const selectApiUrl = (state) => {
    return 'https://anotia-demo-api.totia.es:5001/';
};