import React, { useState, useEffect, useRef } from 'react';
import { Box, Snackbar, Alert, Divider, Pagination, PaginationItem, Paper, Typography, FormControl, RadioGroup, FormControlLabel, Radio, InputLabel, MenuItem , Select, TextField, useTheme } from '@mui/material';

// Contexts
import { useSidebar } from 'utils/SidebarProvider'
import { useDialog } from 'utils/DialogProvider';

// Custom Components
import CustomButton from 'components/common/CustomButton';
import CustomImageNavigation from 'components/common/CustomImageNavigation';
import CustomImageViewer from 'components/common/CustomImageViewer';

const Metadata = () => {
    // States and theme
    const theme = useTheme();
    const [accessToken, setAccessToken] = useState('');

    // Context
    const { metadata, setMetadata, updateMetadata, metadataCompletedList } = useSidebar();
    const { requestConfirm } = useDialog();

    const handleSave = async () => {
        // Check that sex, age and smokes are not empty
        if(metadata.sex === '' || metadata.age === '' || metadata.smokes === '') {
            await requestConfirm('Error', 'Please fill all the fields before saving the data');
            return;
        }
        await updateMetadata(metadata);
    };


    // Render the component
    return(
        <Box display="flex" flexDirection="column" justifyContent="flex-start" flexGrow={1}>

            {/* Basic information */}
            < CustomImageNavigation setAccessToken={setAccessToken} completedList={metadataCompletedList} />

            <Box display="flex" justifyContent="center" alignItems="center" flexGrow={1} gap="1rem" padding="1rem">
                {accessToken !== '' ? (
                    <>
                        <CustomImageViewer accessToken={accessToken} />

                        <Box sx={{ display: 'flex', flexDirection:"column", alignItems: 'center', justifyContent: 'space-between', width: '300px', height: '100%',
                                   padding: '1rem', gap:'1rem', bgcolor:'white', borderRadius: '10px'}}>


                                <FormControl component="fieldset" sx={{ width: '100%'}}>
                                <Typography variant="h5" color={theme.palette.main[400]} sx={{pb:'0.25rem'}}>
                                    <strong>Select the Sex</strong>
                                </Typography>
                                    <RadioGroup
                                        row
                                        aria-label="sex"
                                        name="row-radio-buttons-group"
                                        value={metadata.sex}
                                        onChange={(e) => setMetadata({...metadata, sex: e.target.value})}
                                    >
                                        <Box sx={{ display: 'flex', flexDirection: 'row', gap:'0.25rem', justifyContent:'center' }}>
                                            <FormControlLabel value="female" control={<Radio />} label="Female" />
                                            <FormControlLabel value="male" control={<Radio />} label="Male" />
                                            <FormControlLabel value="other" control={<Radio />} label="Other" />
                                        </Box>
                                    </RadioGroup>
                                </FormControl>

                                <FormControl fullWidth>
                                    <Typography variant="h5" color={theme.palette.main[400]} sx={{pb:'0.5rem'}}>
                                        <strong>Select the Age</strong>
                                    </Typography>
                                    <Select
                                        id="age-select"
                                        value={metadata.age}
                                        onChange={(e) => setMetadata({...metadata, age: e.target.value})}
                                    >
                                        {[...Array(101).keys()].map((ageOption) => (
                                            <MenuItem key={ageOption} value={ageOption}>
                                                {ageOption}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <FormControl component="fieldset" sx={{ width: '100%'}}>
                                    <Typography variant="h5" color={theme.palette.main[400]} sx={{pb:'0.5rem'}}>
                                        <strong>Select if Smokes</strong>
                                    </Typography>
                                    <RadioGroup
                                        row
                                        aria-label="smokes"
                                        name="row-radio-buttons-group"
                                        value={metadata.smokes}
                                        onChange={(e) => setMetadata({...metadata, smokes: e.target.value})}
                                        sx = {{display: 'flex', flexDirection: 'row', gap:'0.25rem', justifyContent:'center'}}
                                    >
                                        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="no" control={<Radio />} label="No" />
                                        <FormControlLabel value="unknown" control={<Radio />} label="Unknown" />
                                    </RadioGroup>
                                </FormControl>

                                <Box sx={{ display: 'flex', flexDirection: 'column', width:'100%', flexGrow:1 }}>
                                    <Typography variant="h5" color={theme.palette.main[400]} sx={{pb:'0.5rem'}}>
                                        <strong>Comments</strong>
                                    </Typography>

                                    <TextField
                                        multiline
                                        variant="outlined"
                                        value={metadata.comments}
                                        onChange={(e) => setMetadata({...metadata, comments: e.target.value})}
                                        sx={{ width: '100%', flex: 1, minHeight: '100px' }}
                                        InputProps={{ sx: {height: '100%', alignItems: 'flex-start', overflow: 'auto'}}}
                                    />
                                </Box>

                                <CustomButton onClick={handleSave} buttonText={"Save"} />
                        </Box>
                    </>
                    ) : (
                        <Box display='flex' flexDirection='column' justifyContent='flex-start' alignItems='center' width='100%' height='100%' padding='1rem'/>
                )}
            </Box>

        </Box>
    );
};

export default Metadata;