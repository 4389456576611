// React and MUI imports
import React, { useEffect, useState} from 'react';
import { Box, Alert, ListItemButton, FormControl, InputLabel, MenuItem, Popover, Select, List, ListItemIcon, ListItemText,
         BottomNavigationAction, BottomNavigation, Tooltip, Typography, Slider, useTheme} from '@mui/material';

// Import icons
import PanToolIcon from '@mui/icons-material/PanTool';
import BrushIcon from '@mui/icons-material/Brush';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import TuneIcon from '@mui/icons-material/Tune';
import SaveIcon from '@mui/icons-material/Save';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import TimelineIcon from '@mui/icons-material/Timeline';

const LabelSelectComponent = ({ labels, selectedLabel, updateState, disabled }) => {
    const theme = useTheme();
    return (
        <FormControl sx={{width: '250px' }} disabled={disabled}>
            <Select
                labelId="label-select-label"
                id="label-select"
                sx={{
                    backgroundColor: theme.palette.grey[100],
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: 'none',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      border: 'none',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      border: 'none',
                    },
                    // Apply padding to ensure the text does not overflow on the color box
                    padding: '0px 8px',
                    display: 'flex',
                    alignItems: 'center',
                    // Ensure the dropdown icon and color box align correctly
                    '.MuiSelect-select': {
                      display: 'flex',
                      alignItems: 'center',
                    }
                }}
                value={selectedLabel ? selectedLabel.label : ''}
                displayEmpty
                onChange={(e) => {
                    const newSelectedLabel = labels.find(label => label.label === e.target.value);
                    updateState({ selectedLabel: newSelectedLabel });
                }}
                renderValue={(selected) => (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ width: 24, height: 24, bgcolor: selectedLabel ? selectedLabel.color : 'transparent', marginRight: 1 }} />
                        {selected}
                    </Box>
                )}
            >
            {labels.map((label, index) => (
                <MenuItem key={index} value={label.label}>
                    <ListItemIcon>
                        <Box sx={{ width: 24, height: 24, bgcolor: label.color }} />
                    </ListItemIcon>
                    <ListItemText primary={label.label} sx={{
                        maxWidth: '250px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }} />
                </MenuItem>
            ))}
            </Select>
        </FormControl>
    );
};

const Mode = {
    NONE: 'NONE',
    DRAWING: 'DRAWING',
    LINE: 'LINE',
    PANNING: 'PANNING',
    DELETE: 'DELETE',
    ADJUST: 'ADJUST',
    ASSISTANT: 'ASSISTANT',
    DOWNLOAD: 'DOWNLOAD',
};

const navigationActions = [
    { label: 'Label', icon: <LocalOfferIcon /> },
    { label: 'Move', icon: <PanToolIcon /> },
    { label: 'Free Contour', icon: <BrushIcon /> },
    { label: 'Line', icon: <TimelineIcon />},
    { label: 'Brush Size', icon: <UnfoldMoreIcon /> },
    { label: 'Adjust', icon: <TuneIcon /> },
    // { label: 'Download', icon: <DownloadIcon /> },
    { label: 'Show', icon: <VisibilityIcon />},
    { label: 'Delete', icon: <DeleteIcon /> },
    { label: 'Save', icon: <SaveIcon /> },
];

const ImageOptionBar = ({ state, updateState, downloadImage, finalizeShape, labels, handleSave, enableIndex, finalizeLine }) => {

    // Toolbar value and disable state
    const [value, setValue] = useState(-1);
    const [disabledButtons, setDisabledButtons] = useState({});
    const disableLabelSelect = state.currentMode === Mode.DRAWING || state.currentMode === Mode.LINE;

    // Altert state
    const [alertInfo, setAlertInfo] = useState({
        show: false,
        label: "",
        severity: "warning"
    });

    // Use effect to enable all the buttons
    useEffect(() => {
        // Enable all the buttons
        const newDisabledButtons = navigationActions.reduce((acc, action, index) => {
            acc[action.label] = false;
            return acc;
        }, {});
        setDisabledButtons(newDisabledButtons);
        handleToolbarChange(null, 1);
    }, [enableIndex]); // eslint-disable-line react-hooks/exhaustive-deps

    // Check if the state.toolbarDisabled is true or false
    useEffect(() => {
        if (state.toolbarDisabled) {
            setDisabledButtons(navigationActions.reduce((acc, action, index) => {
                acc[action.label] = true;
                return acc;
            }, {}));
        } else {
            // Enable all the buttons and set the mode to MOVE
            setDisabledButtons({});
            handleToolbarChange(null, 1);
        }
    }, [state.toolbarDisabled]); // eslint-disable-line react-hooks/exhaustive-deps

    // Use Effect to set the first label as the selected label (once loaded)
    useEffect(() => {
        if (labels.length > 0) {
            updateState({selectedLabel: labels[0]});
        }
    }, [labels]); // eslint-disable-line react-hooks/exhaustive-deps

    // Toolbar options
    const strokeSizes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    const [anchorEl, setAnchorEl] = useState(null);
    const [isLabelOpen, setIsLabelOpen] = useState(false);
    const [isStrokeSizeOpen, setIsStrokeSizeOpen] = useState(false);
    const [isAdjustmentsOpen, setIsAdjustmentsOpen] = useState(false);

    // Handle toolbar change
    const handleToolbarChange = async (event, newValue) => {
        // Get the previous label and the new label
        const previousLabel = (value !== -1) ? navigationActions[value].label : '';
        const newLabel = (newValue !== -1) ? navigationActions[newValue].label : '';

        // Step 2. Set the current mode based on the selected button
        switch (navigationActions[newValue].label) {
            case 'Move':
                // If previous mode was Move, toggle it off
                if (previousLabel === 'Move') {
                    updateState({ currentMode: Mode.NONE });
                    setValue(-1);
                } else {
                    updateState({ currentMode: Mode.PANNING });
                    setValue(newValue);
                }
                break;

            case 'Free Contour':
            case 'Done':
                // If previous mode was Free Contour, toggle it off
                if (newLabel === 'Done' ) {
                    // Change back the action.label to Free Contour
                    navigationActions[newValue].label = 'Free Contour';
                    finalizeShape();
                    updateState({ currentMode: Mode.PANNING });
                    setValue(0);

                    // Enable all the buttons
                    const newDisabledButtons = navigationActions.reduce((acc, action, index) => {
                        acc[action.label] = false;
                        return acc;
                    }, {});
                    setDisabledButtons(newDisabledButtons);

                } if (newLabel === 'Free Contour' ) {
                    // Check if there is a label selected other wise show an alert
                    if (state.selectedLabel.label === '') {
                        setAlertInfo({
                            show: true,
                            label: "Please select a label before annotating.",
                            severity: "warning"
                        });
                        return;
                    }

                    // Change the action.label to Done
                    navigationActions[newValue].label = 'Done';
                    updateState({ currentMode: Mode.DRAWING });
                    setValue(newValue);

                    // Disable all the buttons except the current one
                    const newDisabledButtons = navigationActions.reduce((acc, action, index) => {
                        acc[action.label] = action.label !== 'Done';
                        return acc;
                    }, {});
                    setDisabledButtons(newDisabledButtons);
                }
                break;

                case 'Line':
                    if (state.currentMode === 'LINE') {
                        // Line mode is currently active, toggle it off
                        if (state.tempLine && state.tempLine.points.length > 2) {
                            // Finalize the current line if it has more than one segment
                            finalizeLine(event);
                        }
                        updateState({ currentMode: Mode.NONE, tempLine: null });  // Exit line drawing mode
                        setValue(-1);  // Reset toolbar selection

                        // Re-enable all buttons
                        const reEnabledButtons = navigationActions.reduce((acc, action) => {
                            acc[action.label] = false;
                            return acc;
                        }, {});
                        setDisabledButtons(reEnabledButtons);
                    } else {
                        // Activate line mode and disable all other toolbar buttons
                        updateState({ currentMode: Mode.LINE, tempLine: null });  // Ensure tempLine is reset to start fresh
                        setValue(newValue);

                        const lineDisabledButtons = navigationActions.reduce((acc, action) => {
                            acc[action.label] = (action.label !== 'Line');  // Disable all except the Line button
                            return acc;
                        }, {});
                        setDisabledButtons(lineDisabledButtons);
                    }
                    break;

            case 'Brush Size':
                setAnchorEl(event ? event.currentTarget : null);
                setIsStrokeSizeOpen(true);
                setValue(newValue);
                break;

            case 'Label':
                setAnchorEl(event ? event.currentTarget : null);
                setIsLabelOpen(true);
                setValue(newValue);
                break;

            case 'Delete':
                // If previous mode was Delete, toggle it off
                if (previousLabel === 'Delete') {
                    updateState({ currentMode: Mode.PANNING });
                    setValue(0);
                } else {
                    updateState({ currentMode: Mode.DELETE });
                    setValue(newValue);
                }
                break;

            case 'Adjust':
                setAnchorEl(event ? event.currentTarget : null);
                setIsAdjustmentsOpen(true);
                setValue(0);
                break;

            case 'Download':
                downloadImage();
                setValue(value);
                break;

            case 'Save':
                handleSave();
                setValue(value);
                break;

            default:
                setValue(newValue);
        }
    }

    // Return the Toolbar
    return(
        <>
            <Box sx={{ width: "100%"}}>
                <BottomNavigation showLabels value={value} onChange={handleToolbarChange}>
                    {navigationActions.map((action, index) => {
                        if(action.label === 'Label') {
                            return (<LabelSelectComponent key={index} labels={labels} selectedLabel={state.selectedLabel} updateState={updateState} disabled={disableLabelSelect} />);
                        } else if (action.label === 'Show') {
                            // Dynamically choose the icon based on visibility state
                            const ShowHideIcon = state.visibility ? VisibilityOffIcon : VisibilityIcon;
                            const showHideLabel = state.visibility ? 'Hide' : 'Show';
                            return (
                                <BottomNavigationAction
                                    key={index}
                                    label={showHideLabel}
                                    icon={<ShowHideIcon />}
                                    onClick={() => {
                                        const newVisibility = !state.visibility;
                                        updateState({ ...state, visibility: newVisibility });
                                    }}
                                />
                            );
                        }
                        else{
                            return (<BottomNavigationAction key={index} label={action.label} icon={action.icon} disabled={alertInfo.show || !!disabledButtons[action.label]} />);
                        }
                    })}
                </BottomNavigation>
            </Box>

            {/* Alert */}
            {alertInfo.show &&
                <Alert severity="warning" onClose={() => setAlertInfo({ ...alertInfo, show: false })}>
                    {alertInfo.label}
                </Alert>
            }

            {/* Information on Annotation hover */}
            {state.tooltipOpen && (
                <div style={{ position: 'fixed', left: state.tooltipPosition.x, top: state.tooltipPosition.y }}>
                    <Tooltip
                        title={state.tooltipContent}
                        open={state.tooltipOpen}
                        placement="top"
                    >
                        <div></div>
                    </Tooltip>
                </div>
            )}

            {/* Brush Size Popover */}
            <Popover
                open={isStrokeSizeOpen}
                anchorEl={anchorEl}
                onClose={() => {setIsStrokeSizeOpen(false); handleToolbarChange(null, 1);}}
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                transformOrigin={{vertical: 'center', horizontal: 'center'}}
            >
                <Box>
                    <Select
                        value={state.strokeSize}
                        onChange={(e) => {updateState({strokeSize: e.target.value}); setIsStrokeSizeOpen(false); handleToolbarChange(null, 1);}}
                        fullWidth
                    >
                        {strokeSizes.map(size => (
                            <MenuItem key={size} value={size}>{size}</MenuItem>
                        ))}
                    </Select>
                </Box>
            </Popover>

            {/* Adjustments Popover */}
            <Popover
                open={isAdjustmentsOpen}
                anchorEl={anchorEl}
                onClose={() => setIsAdjustmentsOpen(false)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Box sx={{ p: 2, bgcolor: 'background.paper', width:"300px"}}>
                    <Typography variant="caption">Brightness</Typography>
                    <Slider
                        value={state.brightness}
                        onChange={(e, newValue) => updateState({brightness: newValue})}
                        aria-labelledby="brightness-slider"
                        valueLabelDisplay="auto"
                        step={0.001}
                        min={-0.6}
                        max={0.6}
                    />
                    <Typography variant="caption">Contrast</Typography>
                    <Slider
                        value={state.contrast}
                        onChange={(e, newValue) => updateState({contrast: newValue})}
                        aria-labelledby="contrast-slider"
                        valueLabelDisplay="auto"
                        step={0.5}
                        min={-100}
                        max={100}
                    />
                </Box>
            </Popover>
        </>
    )
}

export default ImageOptionBar;
